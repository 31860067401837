import { CorporatesApiService } from "../../../api/services/corporates-api.service";
import { combineLatest, Observable, Subscription } from "rxjs";
import { distinctUntilChanged, map, skip, switchMap, tap } from "rxjs/operators";
import { IBatchNewsCountResponse } from "../../../api/interfaces/batch-news-count-response.interface";
import { ITopProductTrendsSignal } from "../../../api/interfaces/top-product-trends-signal.interface";
import { FiltersApiService } from "../../../api/services/filters-api.service";
import { IHubApiService } from "../../../api/services/hub-api.service";
import { ICorporatesFiltersContext } from "../../../entities/corporates-filters/state/corporates-filters.facade";
import { ITopNews } from "../../../entities/corporates/interfaces/top-news.interface";
import {
  createCorporateFromHit,
  ICorporate,
} from "../../../entities/corporates/models/corporate.model";
import { ICorporatesContext } from "../../../entities/corporates/state/corporates.facade";
import {
  areArraysOfObjectsWithArrayOfQueryRangesPropsEqual,
  areArraysOfStringsEqual,
  areObjectsWithArrayOfStringsPropsEqual,
  IObjectWithArrayOfStringsProps,
} from "../../../helpers/array.helper";
import { getSelectedFilters } from "../../../helpers/filters.helper";
import { IHubFooterItemData } from "../../../shared/interfaces/hub-footer-item-data.interface";
import { HubQuery } from "./hub.query";
import { HubStore } from "./hub.store";
import { IQueryRanges } from "../../../shared/interfaces/query-ranges.interface";
import {
  isBankruptcy,
  isClient,
  isLead,
  isStartup,
} from "../../../utils/corporates-filters.util";
import {
  ICorporatesLatestSignal,
  mapCompaniesTrend,
} from "../../../pages/hub/HubMain/HubSidePanel/HubSidePanel";
import { combineQueries } from "@datorama/akita";
import { IDocCounts } from "../../../api/interfaces/doc-counts.interface";
import {
  ICorporateHubMarker,
  IHubMapCorporateMarkers,
} from "../../../entities/corporates/interfaces/hub-marker.interface";
import { mapCorporatesToHubMarkers } from "../hooks/hub-markers.hook";
import { sessionFacade } from "../../../state/akita/session/session.facade";
import {
  VKB_CLIENT_NAME,
  VKB_TOP_CLIENTS_STORE_NAME,
  VKB_TOP_LEADS_STORE_NAME,
} from "../../../pages/hub/HubFooter/HubFooterLayouts";
import { IProductTrendsRequest } from "../../../api/interfaces/product-trends-request.interface";
import { corporatesFiltersStore } from "../../../entities/corporates-filters/state/corporates-filters.store";

const TIME_LIMIT_FOR_BANKRUPTCY_IN_DAYS: number = 180;

export interface IHubContext {
  firstLoad$: Observable<boolean>;
  // hubCorporates$: Observable<ICorporate[]>;
  hubCorporatesMarkers$: Observable<ICorporateHubMarker[]>;
  loadingHubCorporates$: Observable<boolean>;
  hubErrorNoData$: Observable<boolean>;

  hubRolloverCorporateId$: Observable<string>;

  cumulativeNewsCount$: Observable<number>;
  // loadingCumulativeNewsCount$: Observable<boolean>;

  hubNewsFooterItems$: Observable<IHubFooterItemData[] | null>;
  loadingTopNews$: Observable<boolean>;

  hubStartUpsFooterItems$: Observable<IHubFooterItemData[]>;
  loadingTopStartUps$: Observable<boolean>;

  loadingAnniversaries$: Observable<boolean>;
  hubAnniversariesFooterItems$: Observable<IHubFooterItemData[]>;

  hubBankruptciesFooterItems$: Observable<IHubFooterItemData[]>;
  loadingTopBankruptcies$: Observable<boolean>;

  hubCompaniesWithTrends$: Observable<string[]>;
  hubCompaniesWithNews$: Observable<string[]>;
  hubCompaniesWithTrendsAndNews$: Observable<string[]>;

  unfilteredHubCorporatesCount$: Observable<number>;

  setHubRolloverCorporate(corporateId: string): void;

  fetchHubCorporates(): Promise<void>;

  fetchNewsCountForCompany(companyId: string): void;

  fetchTrendsCountForCompany(companyId: string): void;

  getHubCorporatesMarkers(): ICorporateHubMarker[];

  fetchAnniversaries(): Promise<void>;

  updateHubMapCorporateMarkers(hubMapCorporateMarkersData: IHubMapCorporateMarkers): void;
}

export class HubFacade implements IHubContext {
  private _selectedFiltersSubscription: Subscription;

  readonly firstLoad$ = this._query.select("firstLoad");

  // readonly hubCorporates$ = this._query.select("corporateIds").pipe(
  //     // tap(
  //     //     async (corporateIds) =>
  //     //         await this._fetchCumulativeNewsCount(corporateIds)
  //     // ),
  //     map((corporateIds) =>
  //         this._corporatesFacade.getCorporatesFromIds(corporateIds)
  //     )
  // );

  readonly hubCorporatesMarkers$ = combineQueries([
    this._query.select("corporateIds"),
    this._corporatesFacade.corporates$,
  ]).pipe(
    map(([corporateIds, corporates]) =>
      this._corporatesFacade.getCorporatesFromIds(corporateIds),
    ),
    tap((hubCorporates: ICorporate[]) => {
      if (hubCorporates) {
        const doc_counts: Partial<IDocCounts> = {
          filtersWithoutValues: {},
        };

        doc_counts.filtersWithoutValues["tags_Client"] = 0;
        doc_counts.filtersWithoutValues["tags_Lead"] = 0;

        // Recalculate client and lead facets when user tags a company
        // in hub
        hubCorporates.forEach((corporate) => {
          if (isClient(corporate)) {
            doc_counts.filtersWithoutValues["tags_Client"] += 1;
          }

          if (isLead(corporate)) {
            doc_counts.filtersWithoutValues["tags_Lead"] += 1;
          }
        });

        this._corporatesFiltersFacade.updateHubTagsFilterDocCounts(doc_counts);
      }
    }),
    map((hubCorporates: ICorporate[]) => {
      let corporatesMarkers: ICorporateHubMarker[] = [];

      if (hubCorporates) {
        corporatesMarkers = mapCorporatesToHubMarkers(hubCorporates);
        this._store.update({ loadingCorporates: false });
      }

      return corporatesMarkers;
    }),
  );

  readonly unfilteredHubCorporatesCount$ = this._query.select("unfilteredHubCorporatesCount");

  readonly hubErrorNoData$ = this._query.select("hubErrorNoData");
  readonly loadingHubCorporates$ = this._query.select("loadingCorporates");

  readonly hubRolloverCorporateId$ = this._query.select("rolloverCorporateId");

  readonly productTrendsVariables$ = this._query
    .select((state) =>
      Object.keys(state)
        .filter((key) => key.startsWith("topPTFooterItems_"))
        .map((key) => key.substring(17)),
    )
    .pipe(
      distinctUntilChanged((old, current) => {
        if (old.length !== current.length) return false;
        current.forEach((currentKey) => {
          if (!old.find((oldKey) => oldKey === currentKey)) return false;
        });
        return true;
      }),
    );

  readonly productTrendsforAllStoreVariables$ = this.productTrendsVariables$.pipe(
    switchMap((variables) =>
      combineLatest(
        variables.map((variable) =>
          combineLatest([
            this._query.select(`loadingTPT_${variable}`),
            this._query.select(`topPTFooterItems_${variable}`),
          ]).pipe(map((values) => (values[0] === false ? values[1] : null))),
        ),
      ).pipe(
        map((values) => {
          return values.filter((values) => values).flat();
        }),
      ),
    ),
  );

  productTrendsforStoreVariable(storeVariable: string = "default") {
    return {
      items$: this._query.select(`topPTFooterItems_${storeVariable}`),
      loading$: this._query.select(`loadingTPT_${storeVariable}`),
    };
  }

  readonly cumulativeNewsCount$ = this._query.select("cumulativeNewsCount");
  // readonly loadingCumulativeNewsCount$ = this._query.select(
  //     "loadingCumulativeNewsCount"
  // );

  readonly hubNewsFooterItems$ = this._query.select("hubNewsFooterItems");
  readonly loadingTopNews$ = this._query.select("loadingTopNews");

  readonly hubStartUpsFooterItems$ = this._query.select("hubStartUpsFooterItems");
  readonly loadingTopStartUps$ = this._query.select("loadingTopStartUps");

  readonly hubAnniversariesFooterItems$ = this._query.select("hubAnniversariesFooterItems");

  readonly loadingAnniversaries$ = this._query.select("loadingAnniversaries");

  readonly hubBankruptciesFooterItems$ = this._query.select("hubBankruptciesFooterItems");
  readonly loadingTopBankruptcies$ = this._query.select("loadingTopBankruptcies");

  readonly hubCompaniesWithTrends$ = this._query.select("companiesWithTrendsIds");
  readonly hubCompaniesWithNews$ = this._query.select("companiesWithNewsIds");
  readonly hubCompaniesWithTrendsAndNews$ = this._query.select(
    "companiesWithTrendsAndNewsIds",
  );

  readonly selectedFilters$ = this._corporatesFiltersFacade.allWithHubUI$.pipe(
    map(getSelectedFilters),
    distinctUntilChanged((p, q) => {
      return (
        areObjectsWithArrayOfStringsPropsEqual(p.filtersWithValues, q.filtersWithValues) &&
        areArraysOfStringsEqual(p.filtersWithoutValues, q.filtersWithoutValues) &&
        areArraysOfObjectsWithArrayOfQueryRangesPropsEqual(p.ranges, q.ranges) &&
        areArraysOfStringsEqual(p.productTrendsFilters, q.productTrendsFilters)
      );
    }),
    skip(1), // to avoid initial value
  );

  constructor(
    private _store: HubStore,
    private _query: HubQuery,
    private _filtersApiService: FiltersApiService,
    private _hubApiService: IHubApiService,
    private _corporatesFacade: ICorporatesContext,
    private _corporatesFiltersFacade: ICorporatesFiltersContext,
    private _corporatesApiService: CorporatesApiService,
  ) {
    this._selectedFiltersSubscription = this.selectedFilters$.subscribe(
      async ({ filtersWithValues, filtersWithoutValues, ranges, productTrendsFilters }) => {
        await this._performHubFiltering(
          filtersWithValues,
          filtersWithoutValues,
          ranges,
          productTrendsFilters,
        );
      },
    );
  }

  setHubRolloverCorporate = (corporateId: string) => {
    this._store.update({
      rolloverCorporateId: corporateId,
    });
  };

  private _mapTopProductTrendsToHubFooterItems(
    topProductTrends: ITopProductTrendsSignal[] | null,
    corporates: ICorporate[],
  ): IHubFooterItemData[] | null {
    if (!topProductTrends) return null;

    let data: IHubFooterItemData[] = [];

    topProductTrends.forEach((trend) => {
      const foundCompany = corporates.find(
        (c) => c.id === trend.company.internal_company_id.toString(),
      );

      if (foundCompany) {
        data.push({
          company: foundCompany,
          newsCount: null,
          trendType: trend.affinity.signal.cluster,
          newItem: trend.new,
        });
      }
    });

    return data;
  }

  private _mapTopNewsToHubFooterItems = (
    topNews: ITopNews[] | null,
    corporates: ICorporate[],
  ): IHubFooterItemData[] | null => {
    if (!topNews) return null;

    let data: IHubFooterItemData[] = [];

    topNews.forEach((newsItem) => {
      const foundCompany = corporates.find(
        (c) => c.id === newsItem.company.internal_company_id.toString(),
      );

      if (foundCompany) {
        data.push({
          company: foundCompany,
          newsCount: null,
          trendType: null,
          companyNewsTitle: newsItem.title,
        });
      }
    });

    return data;
  };

  private _mapTopStartUpsToHubFooterItems = (
    corporates: ICorporate[],
    corporatesTrends: ICorporatesLatestSignal[],
  ): IHubFooterItemData[] => {
    let data: IHubFooterItemData[] = [];

    corporates.forEach((company: ICorporate) => {
      if (isStartup(company)) {
        const startUpCorporateTrend = corporatesTrends.find(
          ({ companyId }) => companyId === company.id,
        );

        data.push({
          company: company,
          newsCount: null,
          trendType: startUpCorporateTrend.companyTrend ?? null,
        });
      }
    });

    return data;
  };

  private _mapTopBankruptciesToHubFooterItems = (
    corporates: ICorporate[],
    corporatesTrends: ICorporatesLatestSignal[],
  ): IHubFooterItemData[] => {
    let data: IHubFooterItemData[] = [];

    corporates.forEach((company: ICorporate) => {
      if (isBankruptcy(company, TIME_LIMIT_FOR_BANKRUPTCY_IN_DAYS)) {
        const bankruptcyCorporateTrend = corporatesTrends.find(
          ({ companyId }) => companyId === company.id,
        );

        data.push({
          company: company,
          newsCount: null,
          trendType: bankruptcyCorporateTrend.companyTrend ?? null,
        });
      }
    });

    return data;
  };

  private async _performHubFiltering(
    filters: IObjectWithArrayOfStringsProps = {},
    filtersWithoutValues: string[],
    ranges: IQueryRanges[],
    productTrendsFilters?: string[],
  ): Promise<{
    corporateIds: string[];
    totalNoFilters: number;
  }> {
    let corporateIds: string[] = null;
    let totalNoFilters = null;

    const corporateFilterEntities = corporatesFiltersStore.getValue().entities;
    const createFilteredEntities = [];

    if (filters?.status && filters.status.includes("others")) {
      createFilteredEntities.push(
        ...corporateFilterEntities["companies_hub_status_others"].filteredEntityIds,
      );
    }

    if (
      filters?.status &&
      (filters.status.includes("Liquidation") ||
        filters.status.includes("Filed for Insolvency"))
    ) {
      createFilteredEntities.push(
        ...corporateFilterEntities["companies_hub_status_bankruptcies"].filteredEntityIds,
      );
    }

    if (filters?.tags && filters.tags.includes("Client")) {
      createFilteredEntities.push(
        ...corporateFilterEntities["companies_hub_tags_Client"].filteredEntityIds,
      );
    }

    if (filters?.tags && filters.tags.includes("Lead")) {
      createFilteredEntities.push(
        ...corporateFilterEntities["companies_hub_tags_Lead"].filteredEntityIds,
      );
    }

    if (ranges.length > 0) {
      createFilteredEntities.push(
        ...corporateFilterEntities["companies_hub_founding_date"].filteredEntityIds,
      );
    }

    this._store.update({
      loadingCorporates: true,
      hubErrorNoData: false,
    });

    try {
      const request = {
        filters,
        filtersWithoutValues,
        ranges,
        productTrendsFilters,
        unfilteredEntityIds: this._query.getValue().unfilteredHubCorporatesIds,
        filteredEntityIds: createFilteredEntities,
      };

      const { corporates, doc_counts, total_no_filters, total, filteredEntityIds } =
        await this._filtersApiService.searchAndFilterHubCorporates(request);

      this._corporatesFiltersFacade.updateHubFilterDocCounts(doc_counts);
      this._corporatesFiltersFacade.updateHubFilterFilteredEntityIds(filteredEntityIds);

      if (corporates.length) {
        this._corporatesFacade.addCorporates(corporates);
      }

      corporateIds = corporates.map(({ id }) => id);
      totalNoFilters = total || total_no_filters;

      this._store.update({
        corporateIds,
      });
    } catch (e) {
      if (e === "hubErrorNoData") {
        this._store.update({
          hubErrorNoData: true,
        });
      } else {
        console.warn("Error while performing corporates filtering: ", e);
      }
    }

    return { corporateIds, totalNoFilters };
  }

  private async _updateTopProductTrends(
    corporateIds: number[],
    storeVariable: string = "default",
    tag?: IProductTrendsRequest["tag"],
  ) {
    this._store.update({ [`loadingTPT_${storeVariable}`]: true } as {
      [key: `loadingTPT_${string}`]: boolean;
    });

    try {
      const res = await this._hubApiService.fetchTopProductTrends({
        companies: corporateIds,
        tag,
      });

      const footerItems = await this._generateHubProductTrendsFooterItems(res.data.signals);

      this._store.update({
        [`topPTFooterItems_${storeVariable}`]: footerItems,
        [`loadingTPT_${storeVariable}`]: false,
      } as {
        [key: `topPTFooterItems_${string}`]: IHubFooterItemData[];
        [key: `loadingTPT_${string}`]: boolean;
      });
    } catch (e) {
      console.warn("Error while fetching top product trends: ", e);
      this._store.update({ [`loadingTPT_${storeVariable}`]: false } as {
        [key: `loadingTPT_${string}`]: boolean;
      });
    }
  }

  async fetchAnniversaries() {
    try {
      const res = await this._filtersApiService.getCompanyAnniversaries();
      const corporates = res.hits.hits
        .filter((item) => item.tags.length > 0)
        .map((item) => ({
          company: createCorporateFromHit(item),
          newsCount: 0,
          trendType: "trend",
        }))
        .sort(
          (a, b) =>
            Date.parse(a.company.anniversary_date) - Date.parse(b.company.anniversary_date),
        );

      this._store.update({
        hubAnniversariesFooterItems: corporates,
      });
    } catch (e) {
      this._store.update({
        hubAnniversariesFooterItems: [],
      });
    }
  }

  async fetchHubCorporates() {
    const { name: clientName } = sessionFacade.getCurrentClient();

    const { corporateIds, totalNoFilters } = await this._performHubFiltering({}, [], []);
    // Potential clients
    this._store.update({
      unfilteredHubCorporatesIds: corporateIds,
      unfilteredHubCorporatesCount: totalNoFilters,
      // hubAnniversariesFooterItems: anniversaries,
    });

    const companiesIds = corporateIds?.map((id) => +id) ?? [];

    if (!companiesIds.length) {
      return;
    }

    const corporatesLatestSignalResponse =
      await this._corporatesApiService.getCorporatesLatestSignal({
        companies: companiesIds,
      });

    this._store.update({
      firstLoad: false,
    });

    // Targets
    const companiesWithTrendsIds =
      await this._filtersApiService.getFilteredCompanyIdsByProductTrends(companiesIds);
    this._store.update({
      companiesWithTrendsIds: companiesWithTrendsIds.map((id) => id.toString()),
    });

    // Potential clients news
    const companiesWithNewsIds = await this._filtersApiService.getFilteredCompaniesByNews(
      companiesIds,
    );
    this._store.update({
      companiesWithNewsIds: companiesWithNewsIds.map((id) => id.toString()),
    });

    // Targets news
    const companiesWithTrendsAndNewsIds =
      await this._filtersApiService.getFilteredCompaniesByNews(companiesWithTrendsIds);
    this._store.update({
      companiesWithTrendsAndNewsIds: companiesWithTrendsAndNewsIds.map((id) => id.toString()),
    });

    if (clientName === VKB_CLIENT_NAME) {
      const corporateIds: number[] = this._store
        .getValue()
        .companiesWithTrendsIds.map((val) => Number(val));

      this._updateTopProductTrends(corporateIds, VKB_TOP_LEADS_STORE_NAME, "Lead");
      this._updateTopProductTrends(corporateIds, VKB_TOP_CLIENTS_STORE_NAME, "Client");
    } else {
      const corporateIds: number[] = this._store
        .getValue()
        .companiesWithTrendsIds.map((val) => Number(val));

      this._updateTopProductTrends(corporateIds);
      this._updateTopNews(companiesIds);
      this._updateTopStartUps(
        companiesIds,
        mapCompaniesTrend(corporatesLatestSignalResponse?.data?.companies),
      );
      this._updateTopBankruptcies(
        companiesIds,
        mapCompaniesTrend(corporatesLatestSignalResponse?.data?.companies),
      );
    }
  }

  /**
   * Cumulative news count is shown in the Hub header
   */
  private async _fetchCumulativeNewsCount(corporateIds: string[]) {
    if (!corporateIds?.length) {
      return;
    }

    // this._store.update({ loadingCumulativeNewsCount: true });

    try {
      const companies = corporateIds.map((id) => +id) ?? [];
      const res = await this._hubApiService.fetchCumulativeNewsCount({
        companies,
      });

      this._store.update({
        cumulativeNewsCount: res.data.count,
      });
    } catch (e) {
      console.warn("Error while fetching cumulative news count: ", e);
    } finally {
      // this._store.update({
      //     loadingCumulativeNewsCount: false,
      // });
    }
  }

  /**
   * News count for specific company in map marker popup
   */
  async fetchNewsCountForCompany(companyId: string) {
    try {
      const res = await this._hubApiService.fetchNewsCountForCompany(companyId);
      console.log("fetchNewsCountForCompany", JSON.stringify(res, null, 4));
    } catch (e) {
      console.warn("Error while fetching news count for company: ", e);
    }
  }

  /**
   * News count for each company in top product trends in footer
   */
  private async _fetchFooterNewsCount(
    footerItems: IHubFooterItemData[],
  ): Promise<IBatchNewsCountResponse> {
    if (!footerItems) return null;

    try {
      const companies = footerItems.map(({ company }) => +company.id) ?? [];
      return await this._hubApiService.fetchFooterNewsCount({
        companies,
      });
    } catch (e) {
      console.warn("Error while fetching cumulative news count: ", e);
    }

    return null;
  }

  /**
   * Product trends count for specific company in map marker popup
   */
  async fetchTrendsCountForCompany(companyId: string) {
    try {
      const res = await this._hubApiService.fetchTrendsCountForCompany(companyId);
      console.log("fetchNewsCountForCompany", JSON.stringify(res, null, 4));
    } catch (e) {
      console.warn("Error while fetching news count for company: ", e);
    }
  }

  private async _updateTopNews(corporateIds: number[]) {
    this._store.update({
      loadingTopNews: true,
    });

    try {
      const res = await this._hubApiService.fetchTopNews({
        companies: corporateIds,
      });

      const footerItems = await this._generateHubNewsFooterItems(
        res.data?.news,
        corporateIds?.map((id) => id?.toString()) ?? [],
      );
      this._store.update({
        hubNewsFooterItems: footerItems,
        loadingTopNews: false,
      });
    } catch (e) {
      console.log("Error while fetching top news: ", e);
      this._store.update({
        loadingTopNews: false,
      });
    } finally {
    }
  }

  private async _updateTopStartUps(
    corporateIds: number[],
    corporatesTrends: ICorporatesLatestSignal[],
  ) {
    this._store.update({
      loadingTopStartUps: true,
    });

    try {
      const startUpFooterItems = this._mapTopStartUpsToHubFooterItems(
        this._corporatesFacade.getCorporatesFromIds(
          corporateIds?.map((id) => id?.toString()) ?? [],
        ),
        corporatesTrends,
      );

      const startUpFooterItemsNews = await this._fetchFooterNewsCount(startUpFooterItems);

      if (!startUpFooterItemsNews) {
        this._store.update({
          hubStartUpsFooterItems: [...startUpFooterItems].slice(0, 15),
        });
      } else {
        const footerItemsWithNewsCount = startUpFooterItems?.map((item) => {
          const foundCompanyFromNewsResponse = startUpFooterItemsNews.data.companies.find(
            (c) => c.company!.internal_company_id.toString() === item.company.id,
          );

          if (foundCompanyFromNewsResponse) {
            item.newsCount = foundCompanyFromNewsResponse.count;
          }

          return item;
        });

        this._store.update({
          hubStartUpsFooterItems: [...footerItemsWithNewsCount].slice(0, 15),
        });
      }
    } catch (e) {
      console.log("Error while fetching top startups", e);
    } finally {
      this._store.update({
        loadingTopStartUps: false,
      });
    }
  }

  private async _updateTopBankruptcies(
    corporateIds: number[],
    corporatesTrends: ICorporatesLatestSignal[],
  ) {
    this._store.update({
      loadingTopBankruptcies: true,
    });

    try {
      const bankruptciesFooterItems = this._mapTopBankruptciesToHubFooterItems(
        this._corporatesFacade.getCorporatesFromIds(
          corporateIds?.map((id) => id?.toString()) ?? [],
        ),
        corporatesTrends,
      );

      const bankruptciesFooterItemsNews = await this._fetchFooterNewsCount(
        bankruptciesFooterItems,
      );

      if (!bankruptciesFooterItemsNews) {
        this._store.update({
          hubBankruptciesFooterItems: [...bankruptciesFooterItems].slice(0, 15),
          loadingTopBankruptcies: false,
        });
      } else {
        const footerItemsWithNewsCount = bankruptciesFooterItems?.map((item) => {
          const foundCompanyFromNewsResponse = bankruptciesFooterItemsNews.data.companies.find(
            (c) => c.company!.internal_company_id.toString() === item.company.id,
          );

          if (foundCompanyFromNewsResponse) {
            item.newsCount = foundCompanyFromNewsResponse.count;
          }

          return item;
        });

        this._store.update({
          hubBankruptciesFooterItems: [...footerItemsWithNewsCount].slice(0, 15),
          loadingTopBankruptcies: false,
        });
      }
    } catch (e) {
      console.log("Error while fetching top bankruptcies", e);
      this._store.update({
        loadingTopBankruptcies: false,
      });
    }
  }

  private async _generateHubProductTrendsFooterItems(
    topProductTrends: ITopProductTrendsSignal[],
  ) {
    const footerItems = this._mapTopProductTrendsToHubFooterItems(
      topProductTrends,
      this._corporatesFacade.getCorporatesFromIds(
        topProductTrends
          .map((t) => t.company.internal_company_id?.toString())
          .filter((t) => t),
      ),
    );

    const footerItemsNews = await this._fetchFooterNewsCount(footerItems);

    if (footerItemsNews) {
      return footerItems?.map((item) => {
        const foundCompanyFromNewsResponse = footerItemsNews.data.companies.find(
          (c) => c.company!.internal_company_id.toString() === item.company.id,
        );

        // If there is a company in the news response that corresponds to the one in the footer
        // update the count, otherwise just leave the item as it is
        if (foundCompanyFromNewsResponse) {
          item.newsCount = foundCompanyFromNewsResponse.count;
        }

        return item;
      });
    }
    return footerItems;
  }

  private async _generateHubNewsFooterItems(topNews: ITopNews[], corporateIds: string[]) {
    const footerItems = this._mapTopNewsToHubFooterItems(
      topNews,
      this._corporatesFacade.getCorporatesFromIds(corporateIds),
    );

    const footerItemsNews = await this._fetchFooterNewsCount(footerItems);

    if (!footerItemsNews) {
      return footerItems;
    } else {
      return footerItems?.map((item) => {
        const foundCompanyFromNewsResponse = footerItemsNews.data.companies.find(
          (c) => c.company!.internal_company_id.toString() === item.company.id,
        );

        if (foundCompanyFromNewsResponse) {
          item.newsCount = foundCompanyFromNewsResponse.count;
        }

        return item;
      });
    }
  }

  getHubCorporatesMarkers() {
    const { corporateIds } = this._query.getValue();
    const hubCorporates = this._corporatesFacade.getCorporatesFromIds(corporateIds);
    return !hubCorporates ? [] : mapCorporatesToHubMarkers(hubCorporates);
  }

  updateHubMapCorporateMarkers = ({ hubCorporateIds, showAll }: IHubMapCorporateMarkers) => {
    const { unfilteredHubCorporatesIds, corporateIds } = this._query.getValue();

    if (showAll) {
      this._store.update({
        corporateIds: unfilteredHubCorporatesIds,
      });

      return;
    }

    if (!hubCorporateIds) {
      hubCorporateIds = corporateIds;
    }

    this._store.update({
      corporateIds: hubCorporateIds,
    });
  };
}
